import { useShowroom } from '../../../services/showrooms/useShowroom';
import { SettlementMethod } from '../types';

export const getSettlementMethodName = (showroomCode: string, settlementMethod: SettlementMethod) => {
  switch (settlementMethod) {
    case SettlementMethod.Cash:
      return 'Cash';
    case SettlementMethod.Finance_FixedRate:
      return showroomCode === 'polestar-nz' ? 'Polestar Finance' : showroomCode === 'cba' ? 'Personal loan' : 'Finance';
    case SettlementMethod.Finance_GuaranteedFutureValue:
      return showroomCode === 'polestar-nz' ? 'Polestar Guarantee' : 'Guaranteed Future Value';
    case SettlementMethod.Subscribe:
      return showroomCode === 'polestar-nz' ? 'Polestar Subscribe' : 'Subscribe';
    case SettlementMethod.Finance_BusinessLease:
      return showroomCode === 'polestar-nz' ? 'Polestar Lease' : 'Commercial Lease';
    case SettlementMethod.Finance_NovatedLease:
      return 'Novated Lease';
    default:
      return null;
  }
};

export const useSettlementMethodName = (settlementMethod: SettlementMethod) => {
  const showroom = useShowroom();

  return getSettlementMethodName(showroom.code, settlementMethod);
};
