import styles from './index.module.scss';
import classnames from 'classnames';
import FormField from '../FormField';
import ReactSlider from 'react-slider';
import { Controller } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import { useDebounce } from '../../services/useDebounce';

export type Props = {
  className?: string;
  label?: React.ReactNode;
  name?: string;
  defaultValue?: number | number[];
  helpText?: React.ReactNode;
  displayedValue?: React.ReactNode;
  min?: number;
  max?: number;
  step?: number;
  rules?: any;
  errors?: any;
  control?: any;
  formFieldProps?: any;
  marks?: boolean;
} & Record<string, any>;

export default function Slider(props: Props) {
  const {
    children,
    className,
    label,
    name,
    displayedValue,
    helpText,
    rules,
    errors,
    defaultValue,
    step,
    formFieldProps,
    marks = false,
    control,
    ...otherProps
  } = props;

  const hasErrors = errors && !!errors[name];

  const formFieldRef = useRef(null);
  const [resizeCount, setResizeCount] = useState(0);
  const [debouncedResizeCount] = useDebounce(resizeCount, 300);

  useEffect(() => {
    if (typeof global.ResizeObserver === 'undefined') {
      return;
    }

    if (!formFieldRef.current) {
      return;
    }
    const observer = new ResizeObserver(() => {
      setResizeCount(resizeCount + 1);
    });

    observer.observe(formFieldRef.current);

    return () => observer.disconnect();
  }, [formFieldRef]);

  return (
    <FormField label={label} name={name} errors={errors} {...formFieldProps}>
      <div ref={formFieldRef} className={classnames(styles.slider, hasErrors && styles.hasErrors)} data-test={name}>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => {
            return (
              <ReactSlider
                key={debouncedResizeCount}
                className={styles.sliderElement}
                thumbClassName={styles.thumb}
                trackClassName={styles.track}
                markClassName={styles.mark}
                step={step}
                marks={marks ? step : false}
                thumbActiveClassName={styles.thumbIsActive}
                renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                {...field}
                onChange={field.onChange}
                {...otherProps}
              />
            );
          }}
        />

        <div>
          <div className={styles.displayedValue}>{displayedValue}</div>
          {helpText && <div className={styles.helpText}>{helpText}</div>}
        </div>
      </div>
    </FormField>
  );
}
