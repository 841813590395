import { isEqual } from 'lodash';
import { gfvTable, GfvTableItem, Rate } from '.';
import { CalculatorConfig } from '../../../types';

type Vehicle = CalculatorConfig['vehicle'];

export const lookup = (
  vehicle: Vehicle,
  annualDrivingDistance: number,
  term: number,
): {
  rate: Rate;
  gfv: GfvTableItem;
} => {
  let packCodes = vehicle.pack_codes;
  if (typeof packCodes === 'string') {
    packCodes = (packCodes as string).split(',');
  }

  const samePacks = (a: string[], b: string[]) => {
    return isEqual([...a].sort(), [...b].sort());
  };

  const gfv = gfvTable.find((gfv) => {
    return (
      gfv.make === vehicle.make &&
      gfv.model === vehicle.model &&
      gfv.badge_code === vehicle.badge_code &&
      gfv.condition.includes(vehicle.condition) &&
      (gfv.pack_codes ? samePacks(gfv.pack_codes, packCodes) : true)
    );
  });

  const kilometres = (annualDrivingDistance * (term / 12)) / 1000;

  const rate = (gfv?.rates || []).find((item) => {
    return item.kilometres === kilometres && item.term === term;
  });

  return { gfv, rate };
};
