import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toDollars, useCurrency } from '../../../../services/formatCurrency';
import { useShowroomSettings } from '../../../../services/showrooms/useShowroomSettings';
import { PriceInput } from '../../../Input/PriceInput';
import Select from '../../../Select';
import Slider from '../../../Slider';

export type Props = {
  className?: string;
  name: string;
  maxCreditAmount: number;
  downpaymentAsSlider?: boolean;
  financeAmount?: number;
};

export const FixedRateCalculatorControls = (props: Props) => {
  const { className, maxCreditAmount, name, downpaymentAsSlider, financeAmount } = props;

  const { d } = useCurrency();
  const { t } = useTranslation('Components:PaymentCalculators:FixedRateFinanceCalculator');

  const showroomSettings = useShowroomSettings([
    'feature_finance_enabled',
    'finance_term_options',
    'finance_fee_schedule',
    'finance_balloon_config',
    'finance_min_credit_amount',
    'finance_max_credit_amount',
  ]);

  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  const data = {
    creditTerm: watch(`${name}.creditTerm`),
    downpayment: watch(`${name}.downpayment`),
    balloon: watch(`${name}.balloon`),
  };

  const downpaymentMax = maxCreditAmount;
  const downpaymentMin = showroomSettings.finance_min_credit_amount || 0;

  const termOptions = [...(showroomSettings.finance_term_options || [])].sort((a, b) => a - b);
  const termMin = termOptions[0];
  const termMax = termOptions[termOptions.length - 1];
  const termStep = termOptions[1] - termOptions[0];

  const balloonOptions = (() => {
    const max = (showroomSettings.finance_balloon_config || []).find((option) => option.term === data.creditTerm)?.max;
    const options = [];

    if (!max) return [];

    for (let i = 0; i <= max * 100; i += 5) {
      const value = i / 100;
      const label = i === 0 ? 'No balloon' : `${i}%`;
      options.push({ label, value: value.toString() });
    }

    return options;
  })();
  const balloonAmount =
    !!financeAmount && balloonOptions.some((op) => op.value === data.balloon) && data.balloon * financeAmount;

  return (
    <div className={className}>
      {downpaymentAsSlider ? (
        <Slider
          name={`${name}.downpayment`}
          type="number"
          label="Deposit / Trade in"
          errors={errors}
          register={register}
          control={control}
          min={downpaymentMin}
          max={downpaymentMax}
          step={500_00}
          helpText={
            <>
              <strong>{d(data.downpayment)}</strong> - {d(downpaymentMin)} to {d(downpaymentMax)}
            </>
          }
        />
      ) : (
        <PriceInput
          name={`${name}.downpayment`}
          type="number"
          label="Deposit / Trade in"
          errors={errors}
          register={register}
          rules={{
            min: {
              value: downpaymentMin,
              message: `Must be at least ${d(downpaymentMin)}`,
            },
            max: {
              value: downpaymentMax,
              message: `Must be at most ${d(downpaymentMax)}`,
            },
            required: true,
          }}
          displayedValue={
            <>
              {d(downpaymentMin)} to {d(downpaymentMax)}
            </>
          }
        />
      )}

      <Slider
        label={t('term', 'Term')}
        name={`${name}.creditTerm`}
        type="number"
        errors={errors}
        control={control}
        register={register}
        min={termMin}
        max={termMax}
        step={termStep}
        displayedValue={
          <>
            {data.creditTerm} {t('months', 'months')}{' '}
            {data.creditTerm % 12 === 0 && (
              <strong>
                ({data.creditTerm === 12 ? t('oneYear', '1 year') : `${data.creditTerm / 12} ${t('years', 'years')}`})
              </strong>
            )}
          </>
        }
      />

      {!!balloonOptions.length && (
        <Select
          label={t('balloonPayment', 'Balloon payment')}
          name={`${name}.balloon`}
          errors={errors}
          control={control}
          defaultValue={'0'}
          register={register}
          options={balloonOptions}
          helpText={
            balloonAmount > 0 &&
            t('totalBalloon', 'Total balloon: {{balloonAmount}}', { balloonAmount: d(balloonAmount) })
          }
        />
      )}

      <Select
        label="Repayment frequency"
        name={`${name}.repaymentFrequency`}
        errors={errors}
        control={control}
        defaultValue={'monthly'}
        register={register}
        options={[
          { label: 'Monthly', value: 'monthly' },
          { label: 'Weekly', value: 'weekly' },
        ]}
      />
    </div>
  );
};
