/**
 * Generated by vyro/scripts/parse-polestar-future-values/readme.md
 */

import data from './data.json';

export interface Rate {
  /** The loan term in months */
  term: number;
  /** The total kilometres over the life of the loan (in thousands, i.e. 20 = 20,000) */
  kilometres: number;
  future_value: number;
}

export interface GfvTableItem {
  make: 'Polestar';
  model: '2';
  badge_code: string;
  pack_codes: string[];
  rates: Rate[];
  condition: string[];
}

export enum ProductCodes {
  Version_SRSM = '534EFPB0K246',
  Version_LRSM = '534EGPB0K246',
  Version_LRDM = '534EDPB0E246',

  Pack_PilotLite = '1276',
  Pack_Plus = 'XPLUSS',
  Pack_Performance = '1150',
}

export const gfvTable = data as Array<GfvTableItem>;
