import { useShowroomSettings } from '../../../../services/showrooms/useShowroomSettings';
import Markdown from '../../../Markdown';

type Props = {
  allowLinks?: boolean;
};

export default function FinanceDisclaimer(props: Props) {
  const { allowLinks } = props;
  const { finance_disclaimer } = useShowroomSettings(['finance_disclaimer']);

  return <Markdown allowLinks={allowLinks}>{finance_disclaimer}</Markdown>;
}
