import { Controller, useFormContext } from 'react-hook-form';
import { BsCurrencyDollar } from 'react-icons/bs';
import Input, { Props as InputProps } from '../index';

interface Props extends InputProps {
  storeAsNegative?: boolean;
}

export const PriceInput = (props: Props) => {
  const { storeAsNegative, rules, ...inputProps } = props;
  const { name } = props;

  const { control } = useFormContext();

  const storedValue = (value: number | string) => {
    let parsed = value;

    if (!parsed) {
      return parsed;
    }

    parsed = Number(value) * 100;

    if (storeAsNegative) {
      parsed = 0 - parsed;
    }

    return parsed;
  };

  const displayedValue = (value: number | string) => {
    let parsed = value;

    if (!parsed) {
      return parsed;
    }

    parsed = Number(value) / 100;

    if (storeAsNegative) {
      parsed = Math.abs(parsed);
    }

    return parsed;
  };

  const { register, ...restInputProps } = inputProps;

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => {
        return (
          <Input
            type="number"
            inputProps={{
              onChange: (e) => field.onChange(storedValue(e.target.value)),
              value: displayedValue(field.value),
            }}
            step={'0.00'}
            prefixIcon={<BsCurrencyDollar />}
            {...restInputProps}
          />
        );
      }}
    />
  );
};
