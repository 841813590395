import { PricingConfigV2 } from '@vyro-x/pricing-client';
import { Vehicle_Orders_Set_Input } from '../../types/graphql';

export enum SettlementMethod {
  Finance_FixedRate = 'finance',
  Finance_NovatedLease = 'finance_novated_lease',
  Subscribe = 'subscribe',
  Finance_GuaranteedFutureValue = 'finance_guaranteed_future_value',
  Finance_BusinessLease = 'business_lease',
  Cash = 'cash',
}

export type CreditConfig = Pick<
  Vehicle_Orders_Set_Input,
  | 'requested_credit_amount'
  | 'requested_credit_term'
  | 'requested_credit_balloon'
  | 'subscription_provider'
  | 'subscription_km'
  | 'subscription_plan_id'
  | 'subscription_term'
  | 'subscription_liability_protection'
>;

export type CalculatorControls = {
  /** the total loan amount, including the balloon amount */
  creditAmount?: number;
  /** the term in months */
  creditTerm?: number;
  /** the balloon amount as a decimal - @example 0.05 (5%) */
  balloon?: number;
  /** the balloon amount - @example 100000 */
  balloonAmount?: number;
  /** the monthly repayment amount */
  repayment?: number;
  /** whether the controls are valid or not */
  isInvalid?: boolean;
  /** whether to include govt. incentives in a repayment */
  includeIncentivesInRepayment?: boolean;
  /** the max annual number of kilometres that the customer drives */
  annualDrivingDistance?: number;

  subscription?: {
    provider: 'sixt';
    planId: string;
    liabilityProtection: boolean;
    km: number;
    term: number;
  };
};

export type CalculatorConfig = {
  driveAwayPrice: number;
  pricingConfig?: PricingConfigV2;
  interestRate: number;
  comparisonRate?: number;
  feeSchedule?: Fee[];
  termOptions: number[];
  depositMin: number;
  depositDefault?: number;
  creditAmount: {
    min: number;
    max: number;
  };
  balloonConfig?: { term: number; max: number }[];
  vehicle?: {
    make: string;
    model: string;
    condition: string;
    badge_code: string;
    pack_codes: string[];
  };
};

export type RepeatingFrequency = 'weekly' | 'fortnightly' | 'monthly';
export type RepaymentFrequency = 'weekly' | 'monthly';

export type Fee = {
  frequency: RepeatingFrequency | 'one-off';
  amount: number;
};
