import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toDollars, useCurrency } from '../../../../services/formatCurrency';
import { n } from '../../../../services/formatNumber';
import { useShowroomSettings } from '../../../../services/showrooms/useShowroomSettings';
import { PriceInput } from '../../../Input/PriceInput';
import Slider from '../../../Slider';

export type Props = {
  className?: string;
  name: string;
  maxCreditAmount: number;
  downpaymentAsSlider?: boolean;
};

export const GuaranteedFutureValueCalculatorControls = (props: Props) => {
  const { className, maxCreditAmount, name, downpaymentAsSlider } = props;

  const { d } = useCurrency();
  const { t } = useTranslation('Components:PaymentCalculators:GuaranteedFutureValueCalculator');

  const showroomSettings = useShowroomSettings([
    'feature_finance_enabled',
    'finance_term_options',
    'finance_fee_schedule',
    'finance_min_credit_amount',
    'finance_max_credit_amount',
  ]);

  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  const data = {
    creditTerm: watch(`${name}.creditTerm`),
    downpayment: watch(`${name}.downpayment`),
    balloon: watch(`${name}.balloon`),
  };

  const downpaymentMax = maxCreditAmount;
  const downpaymentMin = showroomSettings.finance_min_credit_amount || 0;

  const termOptions = [...(showroomSettings.finance_term_options || [])].sort();
  const termMin = termOptions[0];
  const termMax = termOptions[termOptions.length - 1];

  return (
    <div className={className}>
      {downpaymentAsSlider ? (
        <Slider
          name={`${name}.downpayment`}
          type="number"
          label="Deposit / Trade in"
          errors={errors}
          register={register}
          control={control}
          min={downpaymentMin}
          max={downpaymentMax}
          step={500_00}
          displayedValue={
            <>
              <strong>{d(data.downpayment)}</strong> - {d(downpaymentMin)} to {d(downpaymentMax)}
            </>
          }
        />
      ) : (
        <PriceInput
          name={`${name}.downpayment`}
          type="number"
          label="Deposit / Trade in"
          errors={errors}
          register={register}
          rules={{
            min: {
              value: downpaymentMin,
              message: `Must be at least ${d(downpaymentMin)}`,
            },
            max: {
              value: downpaymentMax,
              message: `Must be at most ${d(downpaymentMax)}`,
            },
            required: true,
          }}
          displayedValue={
            <>
              {d(downpaymentMin)} to {d(downpaymentMax)}
            </>
          }
        />
      )}

      <Slider
        label={t('term', 'Term')}
        name={`${name}.creditTerm`}
        type="number"
        errors={errors}
        control={control}
        register={register}
        min={24}
        max={48}
        step={termOptions[1] - termOptions[0]}
        displayedValue={
          <>
            <strong>
              {data.creditTerm === 12 ? t('oneYear', '1 year') : `${data.creditTerm / 12} ${t('years', 'years')}`}
            </strong>{' '}
            ({data.creditTerm} {t('months', 'months')})
          </>
        }
      />

      <Slider
        label="Annual kilometres"
        name={`${name}.annualDrivingDistance`}
        type="number"
        errors={errors}
        control={control}
        register={register}
        min={10_000}
        max={30_000}
        step={5_000}
        helpText={
          <>
            <strong>{n(watch(`${name}.annualDrivingDistance`))} km / year</strong>
          </>
        }
      />
    </div>
  );
};
