import classnames from 'classnames';
import { AiOutlineCaretDown } from 'react-icons/ai';
import FormField, { Props as FormFieldProps } from '../FormField';
import styles from './index.module.scss';

export type Props = {
  children?: React.ReactNode;
  className?: string;
  defaultValue?: string;
  errors?: any;
  helpText?: React.ReactNode;
  color?: 'translucentBlack';
  label?: string;
  name?: string;
  options: Array<{ label: string; value: string; disabled?: boolean }>;
  prefixIcon?: React.ReactNode;
  register?: any;
  rules?: any;
  suffixIcon?: React.ReactNode;
  isDisabled?: boolean;
  formFieldProps?: Partial<FormFieldProps>;
} & Record<string, any>;

export default function Select(props: Props) {
  const {
    children,
    className,
    defaultValue,
    errors,
    helpText,
    color,
    label,
    name,
    options = [],
    prefixIcon,
    register,
    rules,
    suffixIcon = <AiOutlineCaretDown />,
    isDisabled = false,
    formFieldProps = {},
    ...otherProps
  } = props;

  const hasErrors = errors && !!errors[name];

  return (
    <FormField {...formFieldProps} label={label} name={name} helpText={helpText} errors={errors}>
      <div
        className={classnames(
          styles.select,
          hasErrors && styles.hasErrors,
          isDisabled && styles.isDisabled,
          color && styles[color],
          !!prefixIcon && styles.hasPrefixIcon,
          !!suffixIcon && styles.hasSuffixIcon,
        )}
      >
        {prefixIcon && <div className={classnames(styles.icon, styles.prefixIcon)}>{prefixIcon}</div>}

        <select
          id={name}
          name={name}
          disabled={isDisabled}
          defaultValue={defaultValue}
          {...otherProps}
          {...register(name, rules)}
        >
          {options.map((op, i) => (
            <option value={op.value} disabled={op.disabled} key={i}>
              {op.label}
            </option>
          ))}
        </select>

        {suffixIcon && <div className={classnames(styles.icon, styles.suffixIcon)}>{suffixIcon}</div>}
      </div>
    </FormField>
  );
}
