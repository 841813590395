import { useTranslation } from 'react-i18next';
import { useCurrency } from '../../../../services/formatCurrency';
import { n } from '../../../../services/formatNumber';
import List from '../../../List';
import { GuaranteedFutureValueFinance, GuaranteedFutureValueFinanceArgs } from './calculate';
import { useShowroomSettings } from '../../../../services/showrooms/useShowroomSettings';

type Props = {
  vehiclePrice?: number;
  args?: GuaranteedFutureValueFinanceArgs;
  output?: GuaranteedFutureValueFinance;
};

export default function GuaranteedFutureValueCalculatorSummaryTable(props: Props) {
  const { vehiclePrice, args, output } = props;

  const { d } = useCurrency();
  const { t } = useTranslation('Components:PaymentCalculators:GuaranteedFutureValueCalculator');
  const settings = useShowroomSettings(['pricing_drive_away_label'])

  return (
    <div>
      <List
        items={[
          vehiclePrice && { name: t('driveAwayPrice', `${settings.pricing_drive_away_label || 'DriveAway'} price`), description: d(vehiclePrice) },
          output?.loanAmount && { name: t('amountToFinance', 'Amount to finance'), description: d(output.loanAmount) },
          output?.futureValue && { name: t('futureValue', 'Minimum future value'), description: d(output.futureValue) },
          args?.interestRate && {
            name: t('interestRate', 'Interest rate (p.a) *'),
            description: `${n(args.interestRate, 2)}%`,
          },
          args?.comparisonRate && {
            name: t('comparisonRate', 'Comparison rate (p.a) *"'),
            description: `${n(args.comparisonRate, 2)}%`,
          },
        ].filter(Boolean)}
      />
    </div>
  );
}
