import { useMemo } from 'react';
import { useShowroomSettings } from '../../../../services/showrooms/useShowroomSettings';
import { getLoanRepayment } from '../../shared/getLoanRepayment';
import { Fee, RepaymentFrequency } from '../../types';
import { lookup } from './gfvTable/lookup';

export type GuaranteedFutureValueFinanceArgs = {
  downpayment: number;
  vehiclePrice: number;
  creditTerm: number;
  interestRate: number;
  comparisonRate: number;
  annualDrivingDistance: number;
  feeSchedule: Fee[];
  repaymentFrequency: RepaymentFrequency;
  vehicle?: {
    make: string;
    model: string;
    condition: string;
    badge_code: string;
    pack_codes: string[];
  };
  forceFutureValue?: number;
};

export type GuaranteedFutureValueFinance = {
  repayment: number;
  loanAmount: number;
  futureValue: number;
  repaymentFrequency: RepaymentFrequency;
};

export const getGuaranteedFutureValueFinance = ({
  downpayment,
  vehiclePrice,
  creditTerm,
  annualDrivingDistance,
  interestRate,
  comparisonRate,
  feeSchedule,
  repaymentFrequency,
  vehicle,
  forceFutureValue,
}: GuaranteedFutureValueFinanceArgs): GuaranteedFutureValueFinance | null => {
  let futureValue: number;
  if (forceFutureValue) {
    futureValue = forceFutureValue;
  } else if (vehicle) {
    const gfvLookup = lookup(vehicle, annualDrivingDistance, creditTerm);
    futureValue = gfvLookup?.rate?.future_value;
  }

  // This vehicle is not available on GFV
  if (!futureValue) {
    return null;
  }

  const loan = getLoanRepayment({
    creditAmount: vehiclePrice - (downpayment || 0),
    creditTerm,
    balloonAmount: futureValue,
    repaymentFrequency,
    rate: comparisonRate || interestRate,
    feeSchedule,
  });

  return {
    ...loan,
    futureValue,
  };
};

export type GuaranteedFutureValueFinanceShowroomSettings = {
  finance_fee_schedule: Fee[];
  finance_min_deposit: number;
  vehicle_order_min_deposit: number;
  finance_locked_interest_rate: number;
  finance_locked_comparison_rate: number;
  feature_finance_fixed_rate_enabled: boolean;
  finance_repayment_frequency: RepaymentFrequency;
};

export const getGuaranteedFutureValueDefaults = (showroomSettings: GuaranteedFutureValueFinanceShowroomSettings) => {
  return {
    downpayment: 15_000_00,
    creditTerm: 36,
    interestRate: showroomSettings.finance_locked_interest_rate,
    comparisonRate: showroomSettings.finance_locked_comparison_rate,
    annualDrivingDistance: 10_000,
    feeSchedule: showroomSettings.finance_fee_schedule,
    repaymentFrequency: showroomSettings.finance_repayment_frequency || 'monthly',
  };
};

export const useGuaranteedFutureValueDefaults = (): Omit<
  GuaranteedFutureValueFinanceArgs,
  'vehicle' | 'vehiclePrice'
> => {
  const showroomSettings = useShowroomSettings([
    'finance_fee_schedule',
    'finance_min_deposit',
    'vehicle_order_min_deposit',
    'finance_locked_interest_rate',
    'finance_locked_comparison_rate',
    'feature_finance_fixed_rate_enabled',
    'finance_repayment_frequency',
  ]);

  return useMemo(() => getGuaranteedFutureValueDefaults(showroomSettings), [showroomSettings]);
};
